<template>
  <div class="container-slim">
    <div>
      <div class="mt-3" style="font-size: 14px">
        <div>
          <h4 class="fw-500 ml-0  ml-sm-0">
            {{ $route.query.email ? "Edit New User" : inviteNew }}
          </h4>
          <div @click="goBack">
            <span class="s-18 fw-400 cursor-pointer text-black">
              <img src="../../assets/gobacck.png" class="w-12 h-12" alt="" /> Go
              back</span
            >
          </div>
          <div class="pl-3 mt-3" style="font-size: 15px">
            You can invite a new user to access your ChurchPlus account. Only
            give access to people you trust, since users can see your
            transactions and other business information.
          </div>
        </div>
        <div class="row mt-4">
          <div
            class="col-lg-6 col-sm-12 mb-4 pr-3 mt-3 mt-lg-3 pr-sm-3"
            style=""
          >
            <div class="row mb-3">
              <div
                class="col-lg-5 col-sm-12 s-14 fw-500 text-lg-right text-sm-left"
              >
                <label class="">Email</label>
              </div>
              <div class="col-lg-7 col-sm-12">
                <el-input type="text" required v-model="mail" class="w-100" />
              </div>
            </div>
            <div class="row mb-3 mt-2">
              <div
                class="col-lg-5 col-sm-12 s-14 fw-500 text-lg-right text-sm-left"
              >
                <span class="">Phone Number</span>
              </div>
              <div class="col-lg-7 col-sm-12">
                <el-input
                  type="number"
                  class="w-100"
                  required
                  v-model="phoneNumber"
                />
              </div>
            </div>
            <div class="row mb-3 mt-2" v-if="!disabled">
              <div
                class="col-lg-5 col-sm-12 s-14 fw-500 text-lg-right text-sm-left"
              >
                <span class="">Password</span>
              </div>
              <div class="col-lg-7 col-sm-12">
                <el-input
                  type="password"
                  autocomplete="new-password"
                  :disabled="disabled"
                  class="w-100"
                  required
                  v-model="password1"
                />
              </div>
            </div>
            <div class="row mt-2" v-if="!disabled">
              <div
                class="col-lg-5 col-sm-12 s-14 fw-500 text-lg-right text-sm-left"
              >
                <span class="">Confirm Password</span>
              </div>
              <div class="col-lg-7 col-sm-12">
                <el-input
                  type="password"
                  class="w-100"
                  autocomplete="new-password"
                  :disabled="disabled"
                  v-model="password2"
                  required
                />
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-sm-12 mt-4">
                <div class="ml-5 mt-2 mt-lg-0">
                  <span class="col-lg-1 mb-lg-0 mb-3 font-weight-bold"
                    >Roles</span
                  >
                  <div
                    class="col-12 mt-2"
                    v-for="(item, index) in roles"
                    :key="index"
                  >
                    <div
                      v-if="
                        item !== 'FollowUp' &&
                        item !== 'FirsttimerFollowUp' &&
                        item !== 'CanAccessNewConverts' &&
                        item !== 'CanAccessFollowUps' &&
                        item !== 'GroupLeader'
                      "
                    >
                      <el-checkbox
                        v-model="selectedRole"
                        :label="item"
                        @change="handleRoleChange(item)"
                        size="large"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-center p-5" v-if="loading">
                <i
                  class="pi pi-spin pi-spinner text-center text-primary"
                  style="font-size: 3rem"
                ></i>
              </div>
            </div> -->
          </div>

          <!-- <div
            class="col-lg-5 col-sm-12 mt-lg-0 mt-sm-5 mt-md-5 mt-lg-0 mt-5 pl-lg-3 pr-lg-5 pl-sm-3 pr-3 pr-sm-2"
          >
            <div class="row">
              <div class="col-12">
                <h4 class="adminPermission">Admin Permission</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>
                  Best for a business partner, family member or trusted
                  accountant
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="d-flex flex-wrap justify-content-between w-100 role-access p-2"
                  :class="{ 'border-bottom': index == roles.length - 1 }"
                  v-for="(item, index) in roles"
                  :key="index"
                >
                  <div
                    v-if="
                      item !== 'FollowUp' &&
                      item !== 'GroupLeader' &&
                      item !== 'FirsttimerFollowUp' &&
                      item !== 'CanAccessNewConverts' &&
                      item !== 'CanAccessFollowUps'
                    "
                    class="s-12"
                  >
                    {{ item }}
                  </div>
                  <div
                    class="font-weight-600"
                    :class="{
                      'text-success':
                        selectedRole.find((i) => i == item) ||
                        selectedRole.some((i) => i.toLowerCase() == 'admin'),
                    }"
                  >
                    {{
                      selectedRole.find((i) => i == item) ||
                      selectedRole.some((i) => i.toLowerCase() == "admin")
                        ? "Full Access"
                        : "No Access"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-6 col-sm-12">
            <div
              class="col-md-12 px-3 pt-2 pb-3 bg-gray-500 border-radius-border-8"
            >
              <div class="text-center s-18 text-dak fw-500 mb-2">
                Choose Role/Access
              </div>

              <div class="role-options">
                <div
                  class="role-card row justify-content-center mb-3"
                  v-for="(role, index) in roles"
                  :key="index"
                >
                  <div
                    v-if="!excludedRoles.includes(role)"
                    class="col-md-11 p-2 bg-white"
                  >
                    <div class="row justify-content-between align-items-center">
                      <div class="col-md-9 d-flex align-items-start">
                        <el-checkbox
                          v-model="selectedRole"
                          :label="role"
                          class="custom-checkbox"
                        >
                          <div class="role-info ml-2">
                            <div class="role-title">{{ role }}</div>
                            <div
                              class="role-description text-wrap text-break"
                              v-if="role == 'Admin'"
                            >
                              Has Full access to all ChurchPlus features, Manage
                              users, settings, and church database and others
                            </div>
                            <div
                              class="role-description"
                              v-if="role == 'MobileAdmin'"
                            >
                              Can Perform Social and Mobile App tasks via the
                              Software, Oversee basic church online social media
                              and others
                            </div>
                            <div
                              class="role-description"
                              v-if="role == 'BasicUser'"
                            >
                              Can Access the People Feature, They have access to
                              the Communication Feature and otrhers
                            </div>
                            <div
                              class="role-description"
                              v-if="role == 'Reports'"
                            >
                              Can Generate and view reports on attendance,
                              giving, and member engagement. Access statistical
                              insights for church growth and others
                            </div>
                            <div
                              class="role-description"
                              v-if="role == 'CanAccessFirstTimers'"
                            >
                              Can View and manage first-timer records. Add new
                              first-timer details and others
                            </div>
                            <div
                              class="role-description"
                              v-if="role == 'FinancialAccount'"
                            >
                              Manage church finances, tithes, and offerings.
                              Generate financial reports and track expenses and
                              others
                            </div>
                          </div>
                        </el-checkbox>
                      </div>
                      <div
                        class="learn-more cursor-pointer d-flex justify-content-end fw-400 col-md-3 s-8"
                        @click="learnMore(role)"
                      >
                        <div class="d-flex align-items-center">
                          <span>Learn more </span>
                          <span class="d-flex mt-0"
                            ><el-icon><ArrowRight /></el-icon
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center mt-4 button-group">
                <router-link to="/tenant/settings">
                  <el-button round class="discard-btn mr-3" size="large">
                    Discard
                  </el-button>
                </router-link>
                <el-button
                  round
                  size="large"
                  :color="primarycolor"
                  :loading="loading"
                  class="invite-btn"
                  @click="callButton"
                >
                  Invite user
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row mb-lg-5">
          <div class="col-lg-7"></div>
          <div
            class="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-around mt-3"
          >
            <router-link to="/tenant/settings">
              <el-button round class="secondary-button" size="large"
                >Discard</el-button
              ></router-link
            >
            <el-button
              round
              size="large"
              :color="primarycolor"
              :loading="loading" . `q `
              class="px-3 ml-3 text-white"
              @click="callButton"
              >Save User</el-button
            >
          </div>
        </div> -->
      </div>
    </div>
    <el-dialog
      style="border-radius: 20px"
      v-model="showLearnMoreModal"
      :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`"
      top
    >
      <div class="row justify-content-center">
        <div class="col-md-11 userRoles" v-if="setlearnMorItem == 'Admin'">
          <div class="fw-400">Role</div>
          <div class="font-weight-bold s-24 text-dak">
            {{ setlearnMorItem }}
          </div>
          <div class="font-weight-bold text-dak s-14 mt-4">
            What They Can Do:
          </div>
          <ul>
            <li>Full access to all ChurchPlus features.</li>
            <li>Manage users, settings, and church database.</li>
            <li>Oversee finances, reports, and member records.</li>
            <p class="text-dak">N.B: Unlimited access</p>
          </ul>
        </div>
        <div class="col-md-11 userRoles" v-if="setlearnMorItem == 'BasicUser'">
          <div class="fw-400">Role</div>
          <div class="font-weight-bold s-24 text-dak">
            {{ setlearnMorItem }}
          </div>
          <div class="font-weight-bold text-dak s-14 mt-4">
            What They Can Do:
          </div>
          <ul>
            <li>Access the People Feature</li>
            <li>They have access to the Communication Feature.</li>
            <li>Manage and Create Events with Registration and Check-in</li>
            <li>Access to Reports and Social & Mobile App</li>
          </ul>
          <div class="font-weight-bold text-dak s-14 mt-4">
            What They Cannot Do:
          </div>
          <ul>
            <li>Access financial data or sensitive reports</li>
            <li>Modify User settings.</li>
            <li>No access to Form Data</li>
            <li>No access to E-commerce Feature</li>
          </ul>
        </div>
        <div
          class="col-md-11 userRoles"
          v-if="setlearnMorItem == 'MobileAdmin'"
        >
          <div class="fw-400">Role</div>
          <div class="font-weight-bold s-24 text-dak">
            {{ setlearnMorItem }}
          </div>
          <div class="font-weight-bold text-dak s-14 mt-4">
            What They Can Do:
          </div>
          <ul>
            <li>Perform Social and Mobile App tasks via the Software.</li>
            <li>Oversee basic church online social media.</li>
            <li>Can post content via the social and Mobile App.</li>
          </ul>
          <div class="font-weight-bold text-dak s-14 mt-4">
            What They Cannot Do:
          </div>
          <ul>
            <li>No Access to other Features of the ChurchPlus Software</li>
          </ul>
        </div>
        <div
          class="col-md-11 userRoles"
          v-if="setlearnMorItem == 'FinancialAccount'"
        >
          <div class="fw-400">Role</div>
          <div class="font-weight-bold s-24 text-dak">
            {{ setlearnMorItem }}
          </div>
          <div class="font-weight-bold text-dak s-14 mt-4">
            What They Can Do:
          </div>
          <ul>
            <li>Manage church finances, tithes, and offerings.</li>
            <li>Generate financial reports and track expenses.</li>
          </ul>
          <div class="font-weight-bold text-dak mt-4">What They Cannot Do:</div>
          <ul>
            <li>Manage non-financial church records.</li>
            <li>Edit member information or follow-ups.</li>
          </ul>
        </div>
        <div
          class="userRoles col-md-11"
          v-if="setlearnMorItem == 'CanAccessFirstTimers'"
        >
          <div class="fw-400">Role</div>
          <div class="font-weight-bold s-24 text-dak">
            {{ setlearnMorItem }}
          </div>
          <div class="font-weight-bold text-dak s-14 mt-4">
            What They Can Do:
          </div>
          <ul>
            <li>View and manage first-timer records.</li>
            <li>Add new first-timer details.</li>
            <li>Assign follow-up tasks to other team members.</li>
            <li>Send sms, email, and WhatsApp messages to First Timers</li>
            <li>Track follow-ups for new members</li>
          </ul>
        </div>
        <div class="col-md-11 userRoles" v-if="setlearnMorItem == 'Reports'">
          <div class="fw-400">Role</div>
          <div class="font-weight-bold s-24 text-dak">
            {{ setlearnMorItem }}
          </div>
          <div class="font-weight-bold text-dak s-14 mt-4">
            What They Can Do:
          </div>
          <ul>
            <li>
              Generate and view reports on attendance, giving, and member
              engagement.
            </li>
            <li>Access statistical insights for church growth.</li>
            <li>Access to Financial Reports.</li>
          </ul>
        </div>
        <div class="col-md-11 d-flex justify-content-center">
          <el-button
            round
            size="large"
            :color="primarycolor"
            class="text-white"
            @click="okGreat"
          >
            ok great
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import deviceBreakpoint from "../../mixins/deviceBreakpoint";
import axios from "@/gateway/backendapi";
import { ElMessage } from "element-plus";
import userRoles from "../../services/user/userRoles";

// Inject primary color
const primarycolor = inject("primarycolor");

// Component state
const inviteNew = ref("Add New User");
const roles = ref([]);
const selectedRole = ref([]);
const { mdAndUp, lgAndUp, xlAndUp } = deviceBreakpoint();
const setlearnMorItem = ref([]);
const userName = ref("");
const disabled = ref(false);
const showLearnMoreModal = ref(false);
const mail = ref("");
const password1 = ref("");
const password2 = ref("");
const phoneNumber = ref("");
const loading = ref(false);

// Setup router and store
const router = useRouter();
const route = useRoute();
const store = useStore();

const goBack = () => {
  router.go(-1);
};

const excludedRoles = [
  "FollowUp",
  "FirsttimerFollowUp",
  "CanAccessNewConverts",
  "CanAccessFollowUps",
  "GroupLeader",
];

// Computed state
const currentUser = computed(() => store.getters.currentUser);

// Methods
const callButton = () => {
  if (!route.query.email) {
    createNewUser();
  } else {
    updateChurchUser();
  }
};
const learnMore = (item) => {
  showLearnMoreModal.value = true;
  setlearnMorItem.value = item;
};

const okGreat = () => {
  showLearnMoreModal.value = false;
};
const handleRoleChange = (role) => {
  // If role is already selected, remove it; otherwise, add it
  if (selectedRole.value.includes(role)) {
    selectedRole.value = selectedRole.value.filter((r) => r !== role);
  } else {
    selectedRole.value.push(role);
  }
};

const createNewUser = () => {
  if (mail.value === "") {
    ElMessage({
      type: "error",
      message: "Input Your Complete Details",
      duration: 5000,
    });
    return false;
  }

  if (password1.value.length < 6) {
    ElMessage({
      type: "error",
      message: "Ensured Your Password is More than 6 character",
      duration: 5000,
    });
    return false;
  }

  let createNew = {
    email: mail.value,
    password: password1.value,
    name: userName.value,
    roles: selectedRole.value,
    phone: phoneNumber.value,
  };

  loading.value = true;
  axios
    .post(`/api/Settings/CreatNewUser`, createNew)
    .then((res) => {
      ElMessage({
        type: "success",
        message: "New User Saved Successfully",
      });
      console.log(res);
      router.push("/tenant/settings");
    })
    .catch((error) => {
      if (error.response?.data) {
        ElMessage({
          type: "warning",
          message: error.response.data,
          duration: 5000,
        });
      } else {
        ElMessage({
          type: "warning",
          message: "Please check your network and try again",
          duration: 5000,
        });
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const updateChurchUser = async () => {
  let newUpdate = {
    email: mail.value,
    phone: phoneNumber.value,
    password: password1.value,
    name: userName.value,
    roles: selectedRole.value,
  };

  loading.value = true;
  try {
    const res = await axios.put(`/api/Settings/UpdateChurchUser`, newUpdate);
    ElMessage({
      type: "success",
      message: "New User Saved Successfully",
      duration: 5000,
    });
    console.log(res);
    router.push("/tenant/settings");
  } catch (error) {
    console.log(error);
    ElMessage({
      type: "error",
      message: "Failed to update user",
      duration: 5000,
    });
  } finally {
    loading.value = false;
  }
};

const getEmail = async () => {
  if (route.query.email) {
    try {
      loading.value = true;
      const { data } = await axios.get(
        `/api/Settings/GetChurchUserByEmail?email=${route.query.email}`
      );
      mail.value = data.email;
      password1.value = data.password;
      password2.value = data.password;
      phoneNumber.value = data.phone;
      selectedRole.value = data.roles;
      console.log(selectedRole.value, "ffff");
    } catch (error) {
      console.log(error);
      ElMessage({
        type: "error",
        message: "Failed to fetch user data",
        duration: 5000,
      });
    } finally {
      loading.value = false;
    }
  }
};

const fetchUserRole = async () => {
  loading.value = true;
  try {
    const response = await userRoles();
    roles.value = response.data;
  } catch (err) {
    console.log(err);
    ElMessage({
      type: "error",
      message: "Failed to fetch user roles",
      duration: 5000,
    });
  } finally {
    loading.value = false;
  }
};

const fetchCurrentUser = async () => {
  if (!currentUser.value) {
    try {
      const response = await axios.get(
        `/api/Membership/GetCurrentSignedInUser`
      );
      store.commit("setCurrentUser", response.data);
    } catch (error) {
      console.log("Error fetching current user:", error);
    }
  }
};

// Lifecycle hooks
onMounted(async () => {
  if (route.query.email) {
    disabled.value = true;
  }

  await Promise.all([getEmail(), fetchUserRole(), fetchCurrentUser()]);
});
</script>

<!-- <script>
import axios from "@/gateway/backendapi";
import store from "@/store/store";
import { ElMessage } from "element-plus";
import userRoles from "../../services/user/userRoles";
export default {
  components: {},
  inject: ["primarycolor"],
  data() {
    return {
      inviteNew: "Add New User",
      roles: [],
      selectedRole: [],
      userName: "",
      disabled: false,
      disable: false,
      mail: "",
      password1: "",
      password2: "",
      phoneNumber: "",
      name2: "",
      info: null,
      defaultEmail: {},
      currentUser: store.getters.currentUser,
      loading: false,
    };
  },
  methods: {
    callButton() {
      if (!this.$route.query.email) {
        this.createNewUser();
      } else {
        this.updateChurchUser();
      }
    },
    createNewUser() {
      if (this.mail === "") {
        ElMessage({
          type: "error",
          message: "Input Your Complete Details",
          duration: 5000,
        });
        return false;
      }
      if (this.password1.length < 6) {
        ElMessage({
          type: "error",
          message: "Ensured Your Password is More than 6 character",
          duration: 5000,
        });
        return false;
      }
      let createNew = {
        email: this.mail,
        password: this.password1,
        name: this.userName,
        roles: this.selectedRole,
        phone: this.phoneNumber,
      };
      axios
        .post(`/api/Settings/CreatNewUser`, createNew)
        .then((res) => {
          ElMessage({
            type: "success",
            message: "New User Saved Successfully",
          });
          console.log(res);
          this.$router.push("/tenant/settings");
        })
        .catch((error) => {
          if (error.response.data) {
            ElMessage({
              type: "warning",
              message: error.response.data,
              duration: 5000,
            });
          } else {
            ElMessage({
              type: "warning",
              message: "Please check your network and try again",
              duration: 5000,
            });
          }
        });
    },
    async updateChurchUser() {
      let newUpdate = {
        email: this.mail,
        phone: this.phoneNumber,
        password: this.password1,
        name: this.userName,
        roles: this.selectedRole,
      };
      axios
        .put(`/api/Settings/UpdateChurchUser`, newUpdate)
        .then((res) => {
          ElMessage({
            type: "success",
            message: "New User Saved Successfully",
            duration: 5000,
          });
          console.log(res);
          this.$router.push("/tenant/settings");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getEmail() {
      if (this.$route.query.email) {
        try {
          const { data } = await axios.get(
            `/api/Settings/GetChurchUserByEmail?email=${this.$route.query.email}`
          );
          this.mail = data.email;
          this.password1 = data.password;
          this.password2 = data.password;
          this.phoneNumber = data.phone;
          this.selectedRole = data.roles;
          console.log(this.selectedRole, "ffff");
        } catch (error) {
          console.log(error);
        }
      }
    },
    async userRole() {
      this.loading = true;
      try {
        let roles = await userRoles();
        this.loading = false;
        this.roles = roles.data;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
  created() {
    if (this.$route.query.email) {
      this.disabled = true;
    }

    this.getEmail();
    if (!this.currentUser || !store.currentUser) {
      console.log("no current user found");
      axios
        .get(`/api/Membership/GetCurrentSignedInUser`)
        .then((response) => {
          this.currentUser = response.data;
        })
        .catch((error) => console.log(error));
    } else {
      console.log("current user found");
    }
    this.userRole();
  },
  mounted() {
    console.log(store.getters.currentUser);
    if (!store.getters.currentUser || store.getters.currentUser.churchName) {
      axios
        .get(`/api/Membership/GetCurrentSignedInUser`)
        .then((response) => {
          this.currentUser = response.data;
          console.log(response.data);
        })
        .catch((error) => console.log(error));
    }
  },
};
</script> -->

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
}
.userRoles ul li {
  color: #000000 !important;
}

h2 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #333;
}

.role-card {
  border: 1px solid #eee;
  border-radius: 8px;
  transition: all 0.2s;
}

.role-card:hover {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.role-info {
  margin-left: 12px;
}

.role-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
}

.role-description {
  font-size: 10px;
  /* max-width: 75%; */
  color: #666;
  font-weight: 400;
  line-height: 15px;
  white-space: normal; /* Ensures text wraps */
  overflow-wrap: break-word; /* Handles long words */
  word-wrap: break-word; /* For broader browser support */
}

.learn-more {
  color: #6c6b6b;
  cursor: pointer;
}

.button-group {
  gap: 16px;
}

.discard-btn {
  background: white;
  border: 1px solid #ddd;
  color: #666;
  padding: 0 32px;
}

.invite-btn {
  padding: 0 32px;
}

/* Custom checkbox styling */
:deep(.el-checkbox__label) {
  padding-left: 8px;
}

:deep(.el-checkbox__inner) {
  width: 18px;
  height: 18px;
  border-radius: 4px;
}

:deep(.el-checkbox) {
  height: auto;
  align-items: flex-start;
}

:deep(.el-checkbox__input) {
  margin-top: 4px;
}
.role-options .card {
  border: 1px solid #eee;
  transition: all 0.2s;
}
.role-options .card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.form-check-input {
  margin-top: 1.2rem;
  transform: scale(1.2);
}
.form-check-label {
  padding-left: 0.5rem;
  width: 100%;
}
.invite-container {
  width: 100%;
  /* margin-left: 1px; */
  /* height: 800px; */
}
.bg-secondary {
  background-color: #00000063 !important;
}
.new span {
  text-align: right;
}
.table {
  border-radius: 0 !important;
}
.button-add button {
  width: 110px;
}
.btn-outline-secondary:hover {
  background-color: white;
  color: rgb(110, 104, 104);
}

/* .invite-container {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 30px #00000029;
  border: 1px solid #dde2e6;
  border-radius: 10px;
  opacity: 1;
  height: auto;
  padding: 10px 10px;
} */
.rolesBackground {
  color: green;
}

.role-access {
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6;
}

@media (max-width: 771px) {
  .adminPermission {
    margin-top: 4rem !important;
  }
}
</style>
