<template>
  <div>
    <div class="container">
      <div
        class="flex-sm-row flex-column d-flex justify-content-between mt-3 mb-4"
      >
        <div class="">
          <h2 class="font-weight-bold text-head">
            {{ churchProfile ? churchProfile.churchName : "" }}
          </h2>
          <div @click="goBack">
            <span class="s-18 fw-400 cursor-pointer text-black">
              <img src="../../assets/gobacck.png" class="w-12 h-12" alt="" /> Go
              back</span
            >
          </div>
        </div>
        <div class="mt-2 link d-flex align-items-center">
          <div class="mr-3">
            <el-input
              type="text"
              class="w-100"
              placeholder="Search..."
              v-model="searchText"
            />
          </div>
          <div>
            <router-link
              to="/tenant/settings/invitenewuser"
              class="border-0 text-decoration-nonw"
            >
              <el-button round class="primary-bg py-4 text-white">
                Add New User
              </el-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 px-0 mt-5">
        <div class=" s-15 text-dak">
          You can invite a new user to access your Church account. Only give
          access to people you trust, since users can see your transactions and
          other business information.
        </div>
      </div>
      <div class="col-md-12 mt-4">
        <div class="row table-header-row py-3 ">
          <div class="col-md-4">
            <span class="py-3 text-dak fw-500">Email</span>
          </div>
          <div class="col-md-4">
            <span class="py-2 text-dak fw-500">Status</span>
          </div>
          <div class="col-md-3">
            <span class="py-2 text-dak fw-500">Roles</span>
          </div>
          <div class="col-md-1">
            <span class="py-2 fw-500"></span>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div
          class="row small-text tr-border-bottom py-1 header2"
          v-for="(churchMem, index) in searchChurchUser"
          :key="index"
        >
          <div
            class="col-md-4 d-flex justify-content-between align-items-center"
          >
            <span class="py-2 hidden-header">EMAIL</span>
            <span
              class="py-2 text-xs-left"
              v-if="churchMem.email && churchMem.email.length < 10"
            >
              <router-link
                class="route"
                :to="{
                  path: '/tenant/settings/invitenewuser',
                  query: { email: churchMem.email },
                }"
                >{{ churchMem.email }}</router-link
              ></span
            >
            <el-tooltip v-else :content="`${churchMem.email}`" placement="top">
              <router-link
                class="route"
                :to="{
                  path: '/tenant/settings/invitenewuser',
                  query: { email: churchMem.email },
                }"
                >{{ churchMem.email && churchMem.email }}
              </router-link>
            </el-tooltip>
          </div>
          <div
            class="col-md-4 d-flex justify-content-between align-items-center"
          >
            <span class="py-2 hidden-header">STATUS</span>
            <span class="py-2"
              ><router-link
                class="route"
                :to="{
                  path: '/tenant/settings/invitenewuser',
                  query: { email: churchMem.email },
                }"
                >{{ churchMem.status }}</router-link
              ></span
            >
          </div>
          <div
            class="col-md-3 d-flex justify-content-between align-items-center"
          >
            <span class="py-2 hidden-header">ROLES</span>
            <el-tooltip
              class="py-2"
              :content="`${churchMem.roles.join(', ')}`"
              placement="top"
            >
              <router-link
                class="route"
                :to="{
                  path: '/tenant/settings/invitenewuser',
                  query: { email: churchMem.email },
                }"
                >{{
                  `${
                    churchMem && churchMem.roles[0]
                      ? churchMem.roles[0].length > 10
                        ? churchMem.roles[0].substring(0, 10) + ".."
                        : churchMem.roles[0]
                      : ""
                  }`
                }}</router-link
              >
            </el-tooltip>
          </div>
          <div
            class="col-sm-12 d-flex col-12 justify-content-sm-end col-md-1 col-lg-1 col-xl-1 d-flex justify-content-end align-items-center"
          >
            <el-dropdown trigger="click">
              <el-icon>
                <MoreFilled />
              </el-icon>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <router-link
                      class="text-dak text-decoration-none"
                      :to="`/tenant/sms/compose?phone=${churchMem.phone}`"
                      >Send SMS</router-link
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <router-link
                      class="text-dak"
                      v-if="churchMem.email"
                      :to="`/tenant/email/compose?phone=${churchMem.email}`"
                      >Send Email</router-link
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <router-link
                      class="text-dak text-decoration-none"
                      :to="{
                        path: '/tenant/settings/invitenewuser',
                        query: { email: churchMem.email },
                      }"
                    >
                      Edit</router-link
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click.prevent="deletePop(churchMem.email)">
                      <router-link
                        to=""
                        class="text-dak text-decoration-none"
                        v-if="churchMem.email !== getCurrentUser.userEmail"
                      >
                        Delete
                      </router-link>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="deactivateChurchUser(churchMem.email, index)">
                      <router-link class="text-dak text-decoration-none" to="">
                        Inactive
                      </router-link>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="activateChurchUser(churchMem.email, index)">
                      <router-link class="text-dak text-decoration-none" to="">
                        Active
                      </router-link>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="col-12 text-center p-5" v-if="loading">
        <i
          class="pi pi-spin pi-spinner text-center text-primary"
          style="font-size: 3rem"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "@/gateway/backendapi";
import router from "../../router";
import finish from "../../services/progressbar/progress";
export default {
  data() {
    return {
      getCurrentUser: {},
      churchProfile: "",
      searchText: "",
      churchUsers: [],
      churchNames: {},
      loading: false,
      searchIsVisible: false,
    };
  },

  computed: {
    searchChurchUser() {
      if (
        this.churchUsers &&
        this.churchUsers.users &&
        this.churchUsers.users.length > 0 &&
        this.searchText == ""
      )
        return this.churchUsers.users;
      this.churchUsers && this.churchUsers.users
        ? this.churchUsers.users.forEach((i, index) => {
            if (i.email == null) {
              this.churchUsers.users.splice(index, 1);
            }
          })
        : [];
      return this.churchUsers && this.churchUsers.users
        ? this.churchUsers.users.filter((i) =>
            i.email.toLowerCase().includes(this.searchText.toLowerCase())
          )
        : [];
    },
  },
  methods: {
    toggleSearch() {
      this.searchIsVisible = !this.searchIsVisible;
    },
    goBack() {
      router.go(-1);
    },
    removeSearchText() {
      this.searchText = "";
    },
    clearInput() {
      this.searchText = "";
    },

    async churchUser() {
      this.loading = true;
      try {
        const { data } = await axios.get("/api/Settings/ChurchUsers");
        this.churchUsers = data;
        console.log(this.churchUsers, "church users");
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async activateChurchUser(email, index) {
      try {
        let response = await axios.post(
          `/api/Settings/ActivateChurchUser?churchUserEmail=${email}`
        );
        console.log(response);
        this.churchUsers.users[index].status = "Active";
        ElMessage({
          type: "success",
          message: "Status Make Active",
          duration: 5000,
        });
      } catch (error) {
        finish();
        console.log(error);
      }
    },
    async deactivateChurchUser(email, index) {
      try {
        let response = await axios.post(
          `/api/Settings/DeactivateChurchUser?churchUserEmail=${email}`
        );
        console.log(response);
        this.churchUsers.users[index].status = "Inactive";
        ElMessage({
          type: "success",
          message: "Status Make Inactive",
          duration: 5000,
        });
      } catch (error) {
        finish();
        console.log(error);
      }
    },

    async deleteChurchUser(email) {
      try {
        await axios.post(
          `/api/Settings/DeleteChurchUser?churchUserEmail=${email}`
        );
        this.churchUsers.users = this.churchUsers.users.filter(
          (i) => i.email !== email
        );
        ElMessage({
          type: "success",
          message: "Church User Deleted Successfully",
          duration: 5000,
        });
      } catch (error) {
        finish();
        console.log(error);
      }
    },
    deletePop(email) {
      ElMessageBox.confirm(
        "Are you sure you want to proceed?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "error",
        }
      )
        .then(() => {
          this.deleteChurchUser(email);
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
            duration: 5000,
          });
        });
    },
    async currentUser() {
      if (!store.getters.currentUser || !this.getCurrentUser) {
        try {
          const { data } = await axios.get(
            "/api/Membership/GetCurrentSignedInUser"
          );
          this.getCurrentUser = data;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.getCurrentUser = store.getters.currentUser;
        console.log(this.getCurrentUser);
      }
    },
  },
  mounted() {},
  created() {
    if (store.getters.currentUser === new Object()) {
      this.churchProfile = store.getters.currentUser;
      console.log(store.getters.currentUser);
      console.log("current user found");
    } else {
      axios
        .get(`/api/Membership/GetCurrentSignedInUser`)
        .then((response) => {
          this.churchProfile = response.data;
          console.log(this.churchProfile.churchName);
          console.log("no current user found");
        })
        .catch((error) => console.log(error));
    }
    this.churchUser();
    this.currentUser();
  },
};
</script>

<style scoped>
.route {
  color: black !important;
  text-decoration: none !important;
}

.table {
  width: 100% !important;
  /* box-shadow: 0px 1px 4px #02172e45; */
  border: 1px solid #dde2e6;
  border-radius: 20px;
  text-align: left;
  margin-bottom: auto !important;
}

.route:hover {
  color: #136acd !important;
}
.dropdown a {
  color: black !important;
  text-decoration: none;
}
.dropdown a:hover {
  color: #136acd !important;
}

.table-header-row {
  background: #f6f6f6;
  /* border-top: 1px solid #dde2e6; */
  border-radius: 14px;
}
.header2 {
  cursor: pointer;
}

.table-top {
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
}
.table-top label:hover,
.table-top p:hover {
  cursor: pointer;
}

.header2:hover {
  background-color: #eee;
}
.header1 {
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 3px 6px #2c282821;
  border-radius: 8px;
}

.hidden-header {
  display: none;
}
.addnew {
  text-decoration: none !important;
}
.addnew:hover {
  color: white !important;
}
@media screen and (max-width: 1280px) {
  .small-text {
    font-size: 14px;
  }
  /* 
    .link {
        font-size: 14px;
    } */
}

@media screen and (max-width: 767px) {
  .hidden-header {
    display: inline-block;
    font-weight: bold;
  }

  .table-header-row {
    display: none;
  }
}
@media screen and (max-width: 402px) {
  .churchHeader {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .my-con {
    flex-direction: column;
  }
  .table {
    width: 98%;
    margin: 24px auto;
  }
  .summary {
    width: 98%;
    margin: auto;
  }
}
</style>
